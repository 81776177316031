<!-- @author xinzhong -->
<!-- @email xinzhong@yangqianguan.com -->
<!-- @date 2018-09-05 11:25:07.630 -->
<!-- @desc generated by yqg-cli@0.2.12 -->

<template>
    <div class="yqg-title" />
</template>

<script type="text/babel">

    import {instanceMounted, instanceDestroyed} from './util';

    export default {
        name: 'YqgTitle',

        props: {
            title: {
                type: String,
                default: ''
            }
        },

        created() {
            if (this.$isServer) {
                this.$ssrContext.title = decodeURIComponent(this.title);
            }
        },

        mounted() {
            instanceMounted();
            document.title = decodeURIComponent(this.title);
        },

        destroyed() {
            instanceDestroyed();
        }
    };

</script>
